import React, { useCallback, useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase-config";
import "./LoginPage.css"; // reusing the same CSS
import logo from "../assets/bifocal-bytes-logo.png";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const handleRegister = useCallback(
    async (event) => {
      event.preventDefault();
      if (password !== confirmPassword) {
        setError('Passwords do not match.');
        return;
      }
      try {
        await createUserWithEmailAndPassword(auth, email, password);
        navigate("/");
      } catch (error) {
        let errorMessage = error.message;
        errorMessage = errorMessage.replace('Firebase: ', ''); // remove 'Firebase: '
        errorMessage = errorMessage.replace(/ \(auth\//, ' '); // remove ' (auth/'
        errorMessage = errorMessage.replace(').', '.'); // remove ')'
        setError(errorMessage);
      }
    },
    [navigate, email, password, confirmPassword]
  );

  return (
    <div className="matrix-login">
      <h2 className="logo-text">BifocalBytes</h2>
      <img src={logo} alt="Logo" className="logo" />
      <h1>Register</h1>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleRegister}>
        <label>
          Email
          <input
            name="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label>
          Password
          <input
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <label>
          Confirm Password
          <input
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </label>
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default RegisterPage;