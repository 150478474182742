import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { indigo, amber } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import HomePage from "./pages/HomePage";
import SearchDistrictsPage from "./pages/SearchDistrictsPage";
import TopDistrictsByState from "./pages/TopDistrictsByState";
import SearchByCounty from "./pages/SearchCounties";
import ListingsPage from "./pages/ListingsPage";
import "@patternfly/react-core/dist/styles/base.css";
import SearchCities from "./pages/SearchCities";
import BigSearchPage from "./pages/BigSearchPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage.js";
import PrivateRoute from "./PrivateRoute";

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: amber,
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/search" element={<SearchDistrictsPage />} />
          <Route
            path="/top-districts-by-state"
            element={<TopDistrictsByState />}
          />
          <Route path="/search-by-county" element={<SearchByCounty />} />
          <Route path="/search-by-city" element={<SearchCities />} />
          <Route path="/listings" element={<ListingsPage />} />
          <Route path="/listingsbig" element={<BigSearchPage />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
