import React, { useContext } from "react";
import PageContainer from "../components/PageContainer";
import HomePageBanner from "../assets/home-page-banner.jpg";
import "./HomePage.css";
import { AuthContext } from "../Auth";


function HomePage() {
  const { currentUser } = useContext(AuthContext); // Access the current user
  const welcomeMessage = `Welcome to BifocalBytes!!<br />Logged in user: ${currentUser.displayName || currentUser.email}`;

  return (
    <PageContainer className="black-background">
      <img
        src={HomePageBanner}
        alt="Background"
        className="background-image"
      />
      <h1 className="centered-text" dangerouslySetInnerHTML={{ __html: welcomeMessage }}></h1>
    </PageContainer>
  );
}

export default HomePage;
