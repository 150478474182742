import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../firebase-config";
import { AuthContext } from "../Auth";
import "./LoginPage.css";
import logo from "../assets/bifocal-bytes-logo.png";
import googleLogo from "../assets/google.png";

const LoginPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate("/");
      } catch (error) {
        let errorMessage = error.message;
        errorMessage = errorMessage.replace("Firebase: ", ""); // remove 'Firebase: '
        errorMessage = errorMessage.replace(/ \(auth\//, " "); // remove ' (auth/'
        errorMessage = errorMessage.replace(").", "."); // remove ')'
        setError(errorMessage);
      }
    },
    [navigate, email, password]
  );

  const handleGoogleLogin = async (event) => {
    event.preventDefault();
    try {
      await signInWithPopup(auth, new GoogleAuthProvider());
      navigate("/");
    } catch (error) {
      let errorMessage = error.message;
      errorMessage = errorMessage.replace("Firebase: ", ""); // remove 'Firebase: '
      errorMessage = errorMessage.replace(/ \(auth\//, " "); // remove ' (auth/'
      errorMessage = errorMessage.replace(").", "."); // remove ')'
      setError(errorMessage);
    }
  };

  const matrixRainRef = useRef(null);

  useEffect(() => {
    const symbols = "01";
    const container = matrixRainRef.current;
  
    if (container) {
      for (let i = 0; i < 100; i++) {
        const span = document.createElement("span");
        span.textContent = symbols[Math.floor(Math.random() * symbols.length)];
        span.style.left = Math.random() * window.innerWidth + "px";
        span.style.animationDuration = Math.random() * 2 + 2 + "s";
        span.style.animationDelay = Math.random() * 2 + "s";
        container.appendChild(span);
        console.log(`Created and appended span ${i}`);
      }
    }
  }, []);

  if (currentUser) {
    navigate("/");
    return null;
  }

  return (
    <div className="matrix-login">
      <div className="matrix-rain" ref={matrixRainRef}></div>
      <h2 className="logo-text">BifocalBytes</h2>
      <img src={logo} alt="Logo" className="logo" />
      <h1>Log in</h1>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleLogin}>
        <label>
          Email
          <input
            name="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label>
          Password
          <input
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <button type="submit">Log in</button>
        <button
          className="google-login"
          onClick={handleGoogleLogin}
          type="button"
        >
          <img src={googleLogo} alt="Google logo" className="google-logo" />
          Sign in with Google
        </button>
      </form>
      <p>
        Don't have an account? <Link to="/register">Click to Register</Link>
      </p>
    </div>
  );
};

export default LoginPage;
