const DateFormatter = (dateString) => {
  const formatDate = (dateString) => {
    if (!dateString) {
      return "";
    }

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  return formatDate(dateString);
};

export default DateFormatter;
