import React, { useState, useEffect } from "react";
import {
  Button,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  SearchInput,
  Spinner,
} from "@patternfly/react-core";
import { Table, Thead, Tbody, Td, Th, Tr } from "@patternfly/react-table";
import PageContainer from "../components/PageContainer";
import DateFormatter from "../components/FormatDate";
import { useLocation } from "react-router-dom";

export function formatStatus(status) {
  switch (status) {
    case "for_sale":
      return "For sale";
    case "ready_to_build":
      return "Ready to build";
    default:
      return status;
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ListingsPage() {
  const query = useQuery();
  const [citySelected, setCitySelected] = useState(query.get("city") || "");
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columnNames = {
    status: "Status",
    acres: "Acres",
    bath: "Baths",
    bed: "Bedrooms",
    prevSold: "Previously Sold Date",
    price: "Price",
    size: "Size (sq ft)",
  };

  useEffect(() => {
    if (citySelected) {
      fetchListings(citySelected);
    }
  }, [citySelected]);

  const fetchListings = () => {
    if (citySelected) {
      setIsLoading(true);
      fetch(
        `/listings/top-districts/city?city_selected=${encodeURIComponent(
          citySelected
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          setListings(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(
            "Error fetching listings from top districts by city:",
            error
          );
          setIsLoading(false);
        });
    }
  };

  return (
    <PageContainer>
      <Toolbar>
        <ToolbarContent>
          <ToolbarItem>
            <SearchInput
              aria-label="Listings input"
              type="text"
              value={citySelected}
              onChange={(e) => setCitySelected(e.target.value)}
              placeholder="Enter city for listings"
            />
          </ToolbarItem>
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Button onClick={() => fetchListings(citySelected)}>
              Search Listings
            </Button>
          </ToolbarItem>
          {isLoading && (
            <ToolbarItem>
              <Spinner size="lg" />
            </ToolbarItem>
          )}
        </ToolbarContent>
      </Toolbar>
      <Table aria-label="Top districts by state table" variant="default">
        <Thead>
          <Tr>
            <Th>{columnNames.status}</Th>
            <Th>{columnNames.acres}</Th>
            <Th>{columnNames.bath}</Th>
            <Th>{columnNames.bed}</Th>
            <Th>{columnNames.prevSold}</Th>
            <Th>{columnNames.price}</Th>
            <Th>{columnNames.size}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {listings.map((listing, index) => (
            <Tr key={index}>
              <Td dataLabel={columnNames.name}>
                {formatStatus(listing["Status"])}
              </Td>
              <Td dataLabel={columnNames.name}>
                {listing["acre_lot"] ?? "N/A"}
              </Td>
              <Td dataLabel={columnNames.name}>{listing["bath"] || "N/A"}</Td>
              <Td dataLabel={columnNames.name}>{listing["bed"]}</Td>
              <Td dataLabel={columnNames.name}>
                {DateFormatter(listing["prev_sold_date"]) || "N/A"}
              </Td>
              <Td dataLabel={columnNames.name}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(listing["price"])}
              </Td>
              <Td dataLabel={columnNames.name}>
                {new Intl.NumberFormat("en-US").format(listing["size"])}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </PageContainer>
  );
}

export default ListingsPage;
