import React, { useState } from "react";
import {
  Button,
  Spinner,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  SearchInput,
} from "@patternfly/react-core";
import { Table, Thead, Tbody, Td, Th, Tr } from "@patternfly/react-table";
import PageContainer from "../components/PageContainer";

function SearchDistrictsPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [districts, setDistricts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columnNames = {
    name: "District Name",
    countyName: "County",
    cityName: "City",
    stateAbb: "State",
    minGrade: "Minimum Grade",
    maxGrade: "Maximum Grade",
    score: "Most Recent Average Score",
  };

  const handleSearch = () => {
    setIsLoading(true);
    fetch(`/search-district?name=${encodeURIComponent(searchTerm)}`)
      .then((response) => response.json())
      .then((data) => {
        setDistricts(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching district data:", error);
        setIsLoading(false);
      });
  };

  return (
    <PageContainer>
      <Toolbar>
        <ToolbarContent>
          <ToolbarItem>
            <SearchInput
              aria-label="District name"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter district name"
            />
          </ToolbarItem>
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Button onClick={handleSearch} variant="primary">
              Search Districts
            </Button>
          </ToolbarItem>
          {isLoading && (
            <ToolbarItem>
              <Spinner size="lg" />
            </ToolbarItem>
          )}
        </ToolbarContent>
      </Toolbar>
      <Table aria-label="Top districts by state table" variant="default">
        <Thead>
          <Tr>
            <Th>{columnNames.name}</Th>
            <Th>{columnNames.countyName}</Th>
            <Th>{columnNames.cityName}</Th>
            <Th>{columnNames.stateAbb}</Th>
            <Th>{columnNames.minGrade}</Th>
            <Th>{columnNames.maxGrade}</Th>
            <Th>{columnNames.score}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {districts.map((district, index) => (
            <Tr key={index}>
              <Td dataLabel={columnNames.name}>{district["DistName"]}</Td>
              <Td dataLabel={columnNames.countyName}>
                {district["CountyName"]}
              </Td>
              <Td dataLabel={columnNames.cityName}>{district["City"]}</Td>
              <Td dataLabel={columnNames.stateAbb}>{district["StateAbb"]}</Td>
              <Td dataLabel={columnNames.minGrade}>{district["MinGrade"]}</Td>
              <Td dataLabel={columnNames.maxGrade}>{district["MaxGrade"]}</Td>
              <Td dataLabel={columnNames.score}>{district["average"]}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </PageContainer>
  );
}

export default SearchDistrictsPage;
