import React, { useContext } from "react";
import { auth } from "../firebase-config";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth";
import {
  Brand,
  Flex,
  FlexItem,
  Masthead,
  MastheadBrand,
  MastheadMain,
  MastheadToggle,
  Nav,
  NavItem,
  NavList,
  Page,
  PageSidebar,
  PageSidebarBody,
  PageToggleButton,
  TextVariants,
  Text,
  Title,
  TitleSizes,
} from "@patternfly/react-core";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faSearch,
  faChartBar,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import BifocalBytesLogo from "../assets/bifocal-bytes-logo.png";
import BarsIcon from "@patternfly/react-icons/dist/esm/icons/bars-icon";

const PageContainer = ({ children }) => {
  const title = "Bifocal Bytes";
  const location = "Github Repo";
  const mastheadTitle = title.toUpperCase().split("").join("  ");
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext); // Access the current user

  const [sideBarState, setSideBarState] = React.useState(true);

  const onSidebarToggle = () => {
    setSideBarState(!sideBarState);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // navigate to login page after successful logout
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const navItems = [
    { title: "Home", href: "/", icon: faHome },
    { title: "Search Districts", href: "/search", icon: faSearch },
    {
      title: "Top Districts by State",
      href: "/top-districts-by-state",
      icon: faSearch,
    },
    { title: "Search by County", href: "/search-by-county", icon: faSearch },
    { title: "Search by City", href: "/search-by-city", icon: faSearch },
    { title: "Listings", href: "/listings", icon: faChartBar },
    { title: "Big Search", href: "/listingsbig", icon: faChartBar },
    { title: "Log Out", icon: faSignOutAlt, onClick: handleLogout },
  ];

  const styles = {
    closed: {
      alignItems: "center",
      height: 56,
    },
    closeSidebar: {
      transition: "width 0.1s",
      display: "flex",
      flex: 1,
      width: "65px",
    },
    link: {
      alignItems: "center",
      minHeight: 56,
      marginRight: 70,
      fontFamily: "Red Hat Text, sans-serif",
      lineHeight: "24px",
    },
    openSidebar: {
      transition: "width 0.1s",
      display: "flex",
      flex: 1,
      width: "250px",
    },
  };

  const header = (
    <Masthead display={{ default: "inline" }} id="light-masthead">
      <MastheadToggle>
        <PageToggleButton
          variant="plain"
          aria-label="Global navigation"
          isSidebarOpen={sideBarState}
          onSidebarToggle={onSidebarToggle}
          id="vertical-nav-toggle"
          data-testid="bars-icon"
        >
          <BarsIcon />
        </PageToggleButton>
      </MastheadToggle>
      <MastheadMain>
        <MastheadBrand style={{ paddingRight: 8 }}>
          <Brand
            alt="Logo"
            heights={{ default: "29.97px" }}
            src={BifocalBytesLogo}
            style={{ paddingRight: 24 }}
          />
          <Title
            headingLevel="h1"
            size={TitleSizes.lg}
            style={{
              alignSelf: "center",
              fontWeight: 800,
              display: "inline-block",
            }}
          >
            {mastheadTitle}
          </Title>
        </MastheadBrand>
        <Flex>
          <FlexItem
            alignSelf={{ default: "alignSelfCenter" }}
            spacer={{ default: "spacerXl" }}
            style={{ marginLeft: "2em" }} // Add this line
          >
            <Text
              component={TextVariants.a}
              href="https://github.com/BifocalBytes/webapp"
              style={{ color: "limegreen" }} // Add this line
            >
              {location}
            </Text>
          </FlexItem>
          <FlexItem alignSelf={{ default: "alignSelfCenter" }}>
            <Text
              component={TextVariants.p}
              style={{ marginLeft: "1em", color: "limegreen" }}
            >
              {currentUser.displayName || currentUser.email}
            </Text>
          </FlexItem>
        </Flex>
      </MastheadMain>
    </Masthead>
  );

  const sidebar = (
    <PageSidebar
      style={{ ...(sideBarState ? styles.openSidebar : styles.closeSidebar) }}
    >
      <PageSidebarBody>
        <Nav>
          <NavList>
            {navItems.map((item, index) =>
              item.title === "Log Out" ? (
                <NavItem key={index} onClick={item.onClick}>
                  <FontAwesomeIcon
                    icon={item.icon}
                    style={{ marginRight: 8 }}
                  />
                  {sideBarState ? item.title : ""}
                </NavItem>
              ) : (
                <NavItem
                  key={item.href}
                  isActive={location.pathname === item.href}
                >
                  <Link
                    to={item.href}
                    style={sideBarState ? styles.link : styles.closed}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      style={{ marginRight: 8 }}
                    />
                    {sideBarState ? item.title : ""}
                  </Link>
                </NavItem>
              )
            )}
          </NavList>
        </Nav>
      </PageSidebarBody>
    </PageSidebar>
  );

  return (
    <Page
      header={header}
      sidebar={sidebar}
      style={{ backgroundColor: "black" }}
    >
      {children}
    </Page>
  );
};

export default PageContainer;
