import React, { useState } from "react";
import {
  Button,
  Spinner,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  SearchInput,
} from "@patternfly/react-core";
import { Table, Thead, Tbody, Td, Th, Tr } from "@patternfly/react-table";
import PageContainer from "../components/PageContainer";

function TopDistrictsByState() {
  const [stateAbbreviation, setStateAbbreviation] = useState("");
  const [topDistricts, setTopDistricts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columnNames = {
    name: "District Name",
    cityName: "City",
    score: "Most Recent Score",
  };

  const handleTopDistrictsSearch = () => {
    setIsLoading(true);
    fetch(`/top-districts/state/${stateAbbreviation}`)
      .then((response) => response.json())
      .then((data) => {
        setTopDistricts(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching top districts by state:", error);
        setIsLoading(false);
      });
  };

  return (
    <PageContainer>
      <Toolbar>
        <ToolbarContent>
          <ToolbarItem>
            <SearchInput
              aria-label="Search top districts by state"
              type="text"
              value={stateAbbreviation}
              onChange={(e) => setStateAbbreviation(e.target.value)}
              placeholder="Enter state abbreviation"
            />
          </ToolbarItem>
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Button onClick={handleTopDistrictsSearch}>
              Search Top Districts by State
            </Button>
          </ToolbarItem>
          {isLoading && (
            <ToolbarItem>
              <Spinner size="lg" />
            </ToolbarItem>
          )}
        </ToolbarContent>
      </Toolbar>
      <Table aria-label="Top districts by state table" variant="default">
        <Thead>
          <Tr>
            <Th>{columnNames.name}</Th>
            <Th>{columnNames.cityName}</Th>
            <Th>{columnNames.score}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {topDistricts.map((district, index) => (
            <Tr key={index}>
              <Td dataLabel={columnNames.name}>{district["District Name"]}</Td>
              <Td dataLabel={columnNames.cityName}>{district.CityName}</Td>
              <Td dataLabel={columnNames.score}>{district.Scores}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </PageContainer>
  );
}

export default TopDistrictsByState;
