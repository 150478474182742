import React, { useState } from "react";
import {
  Button,
  Spinner,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  SearchInput,
} from "@patternfly/react-core";
import { Table, Thead, Tbody, Td, Th, Tr } from "@patternfly/react-table";
import PageContainer from "../components/PageContainer";
import { useNavigate } from "react-router-dom";

function SearchCities() {
  const [cityName, setCityName] = useState("");
  const navigate = useNavigate();
  const [topCities, setTopCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columnNames = {
    name: "District Name",
    score: "Average Score",
  };

  const handleTopCitiesSearch = () => {
    setIsLoading(true);
    fetch(`/top-districts/city?city_name=${encodeURIComponent(cityName)}`)
      .then((response) => response.json())
      .then((data) => {
        setTopCities(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching top districts by city:", error);
        setIsLoading(false);
      });
  };

  const handleSeeListings = () => {
    setIsLoading(true);
    navigate(`/listings?city=${encodeURIComponent(cityName)}`);
    setIsLoading(false);
  };

  return (
    <PageContainer>
      <Toolbar>
        <ToolbarContent>
          <ToolbarItem>
            <SearchInput
              aria-label="Search by city"
              type="text"
              value={cityName}
              onChange={(e) => setCityName(e.target.value)}
              placeholder="Enter city name"
            />
          </ToolbarItem>
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Button onClick={handleTopCitiesSearch}>
              Search Top Districts by City
            </Button>
          </ToolbarItem>
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Button onClick={handleSeeListings} variant="secondary">
              See Listings
            </Button>
          </ToolbarItem>
          {isLoading && (
            <ToolbarItem>
              <Spinner size="lg" />
            </ToolbarItem>
          )}
        </ToolbarContent>
      </Toolbar>
      <Table aria-label="Top districts by state table" variant="default">
        <Thead>
          <Tr>
            <Th>{columnNames.name}</Th>
            <Th>{columnNames.score}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {topCities.map((district, index) => (
            <Tr key={index}>
              <Td dataLabel={columnNames.name}>{district["District Name"]}</Td>
              <Td dataLabel={columnNames.score}>{district.Scores}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </PageContainer>
  );
}

export default SearchCities;
