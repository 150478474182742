import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Container, Grid, Slider } from "@mui/material";
import {
  Button,
  Spinner,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  SearchInput,
} from "@patternfly/react-core";
import { Table, Thead, Tbody, Td, Th, Tr } from "@patternfly/react-table";
import PageContainer from "../components/PageContainer";

function BigSearchPage() {
  const [stateSelected, setStateSelected] = useState("");
  const [bed, setBed] = useState([0, 100]);
  const [bath, setBath] = useState([0, 100]);
  const [citySelected, setCitySelected] = useState("");
  const [zipSelected, setZipSelected] = useState("");
  const [acre, setAcre] = useState([0, 1000000]);
  const [size, setSize] = useState([0, 1000000]);
  const [price, setPrice] = useState([0, 100000000]);
  const [minScore, setMinScore] = useState("-4");
  const [listings, setListingsBig] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columnNames = {
    listingID: "Listing ID",
    cityName: "City",
    stateAbb: "State",
    zipCode: "Zip Code",
    numBed: "Bedrooms",
    numBath: "Bathrooms",
    acreLot: "Lot size (acres)",
    size: "House Size (sqft)",
    price: "Price ($)",
    topDistrict: "Top Close District",
    topDistrictScore: "District Score",
  };

  const handleBigListingSearch = () => {
    setIsLoading(true);
    fetch(
      `/listings/big-search?city_selected=${citySelected}&state_selected=${stateSelected}&zip_selected=${zipSelected}` +
        `&bed_min=${bed[0]}&bed_max=${bed[1]}` +
        `&bath_min=${bath[0]}&bath_max=${bath[1]}&acre_min=${acre[0]}&acre_max=${acre[1]}&size_min=${size[0]}&size_max=${size[1]}` +
        `&price_min=${price[0]}&price_max=${price[1]}&min_score=${minScore}`
    )
      .then((response) => response.json())
      .then((data) => {
        setListingsBig(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching listings:", error);
        setIsLoading(false);
      });
  };

  return (
    <PageContainer>
      <Toolbar>
        <ToolbarContent>
          <ToolbarItem>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <SearchInput
                    aria-label="Search top districts by state"
                    type="text"
                    value={citySelected}
                    onChange={(e) => setCitySelected(e.target.value)}
                    placeholder="City"
                  />
                </Grid>
                <Grid item xs={1}>
                  <SearchInput
                    aria-label="Search top districts by state"
                    type="text"
                    value={stateSelected}
                    onChange={(e) => setStateSelected(e.target.value)}
                    placeholder="State"
                  />
                </Grid>
                <Grid item xs={2}>
                  <SearchInput
                    aria-label="Search top districts by state"
                    type="text"
                    value={zipSelected}
                    onChange={(e) => setZipSelected(e.target.value)}
                    placeholder="Zip Code"
                  />
                </Grid>
                <Grid item xs={3}>
                  <p>Minimum District Score</p>
                  <Slider
                    marks={true}
                    value={minScore}
                    min={-4}
                    max={4}
                    step={0.25}
                    onChange={(e, newValue) => setMinScore(newValue)}
                    valueLabelDisplay="auto"
                    color="#ffff"
                    track={false}
                    data-testid="district-score-slider"
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Bedrooms</p>
                  <Slider
                    marks={true}
                    value={bed}
                    min={0}
                    max={20}
                    step={1}
                    onChange={(e, newValue) => setBed(newValue)}
                    valueLabelDisplay="auto"
                    data-testid="bedroom-slider"
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Bathrooms</p>
                  <Slider
                    marks={true}
                    value={bath}
                    min={0}
                    max={20}
                    step={0.5}
                    onChange={(e, newValue) => setBath(newValue)}
                    valueLabelDisplay="auto"
                    data-testid="bathroom-slider"
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>Lot Size (acres)</p>
                  <Slider
                    value={acre}
                    min={0}
                    max={100000}
                    step={1000}
                    onChange={(e, newValue) => setAcre(newValue)}
                    valueLabelDisplay="auto"
                    data-testid="lot-size-slider"
                  />
                </Grid>
                <Grid item xs={6}>
                  <p>House Size (sq ft)</p>
                  <Slider
                    value={size}
                    min={0}
                    max={100000}
                    step={500}
                    onChange={(e, newValue) => setSize(newValue)}
                    valueLabelDisplay="auto"
                    data-testid="house-size-slider"
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>Price ($)</p>
                  <Slider
                    value={price}
                    min={0}
                    max={100000000}
                    step={10000}
                    onChange={(e, newValue) => setPrice(newValue)}
                    valueLabelDisplay="auto"
                    data-testid="price-slider"
                  />
                </Grid>
              </Grid>
            </Container>
          </ToolbarItem>
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Button onClick={handleBigListingSearch}>Search Listings</Button>
          </ToolbarItem>
          {isLoading && (
            <ToolbarItem>
              <Spinner size="lg" />
            </ToolbarItem>
          )}
        </ToolbarContent>
      </Toolbar>
      <Table aria-label="Top districts by state table" variant="default">
        <Thead>
          <Tr>
            <Th>{columnNames.listingID}</Th>
            <Th>{columnNames.cityName}</Th>
            <Th>{columnNames.stateAbb}</Th>
            <Th>{columnNames.zipCode}</Th>
            <Th>{columnNames.numBed}</Th>
            <Th>{columnNames.numBath}</Th>
            <Th>{columnNames.acreLot}</Th>
            <Th>{columnNames.size}</Th>
            <Th>{columnNames.price}</Th>
            <Th>{columnNames.topDistrict}</Th>
            <Th>{columnNames.topDistrictScore}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {listings.map((listing, index) => (
            <Tr key={index}>
              <Td dataLabel={columnNames.name}>{listing["HID"]}</Td>
              <Td dataLabel={columnNames.name}>{listing["CityName"]}</Td>
              <Td dataLabel={columnNames.name}>{listing["StateName"]}</Td>
              <Td dataLabel={columnNames.name}>{listing["zipcode"]}</Td>
              <Td dataLabel={columnNames.name}>{listing["bed"]}</Td>
              <Td dataLabel={columnNames.name}>{listing["bath"]}</Td>
              <Td dataLabel={columnNames.name}>{listing["acre_lot"]}</Td>
              <Td dataLabel={columnNames.name}>{listing["size"]}</Td>
              <Td dataLabel={columnNames.name}>{listing["price"]}</Td>
              <Td dataLabel={columnNames.name}>{listing["DName"]}</Td>
              <Td dataLabel={columnNames.name}>{listing["avg_score"]}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </PageContainer>
  );
}

export default BigSearchPage;
