import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCfAFe5dWLTieof5X8Sb_oKM2oeMC9RtVg",
  authDomain: "bifocal-bytes.firebaseapp.com",
  projectId: "bifocal-bytes",
  storageBucket: "bifocal-bytes.appspot.com",
  messagingSenderId: "904044393363",
  appId: "1:904044393363:web:6d26866a6dddeacb392e66",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
